<template>
  <div class="wap-mine-setting">
    <headerBar :title="$t('ji-chu-xin-xi-0')" @back="back"></headerBar>
    <div class="wap-form">
      <div class="form-item">
        <div class="label">{{ $t('dian-pu-logo-0') }}</div>
        <div class="upload">
          <el-upload
            class="avatar-uploader"
            :show-file-list="false"
            :action="uploadUrl"
            name="file"
            :headers="headers"
            :on-success="uploadSuccess"
            :before-upload="beforeUpload"
          >
            <img v-if="form.Logo" :src="getBaseUrl(form.Logo)" class="avatar" />
            <i
              v-else
              class="el-icon-camera-solid"
              style="font-size: 30px; color: #aaa"
            ></i>
          </el-upload>
        </div>
      </div>

      <div class="form-item">
        <Field
          v-model="form.Name"
          :label="$t('dian-pu-ming-cheng')"
          :placeholder="$t('qing-shu-ru-dian-pu-ming-cheng-4')"
        ></Field>
        <Field
          v-model="form.Contact"
          :label="$t('lian-xi-ren')"
          :placeholder="$t('qing-shu-ru-lian-xi-ren-1')"
        ></Field>
        <Field
          v-model="form.Phone"
          :label="$t('dian-pu-dian-hua')"
          :placeholder="$t('qing-shu-ru-dian-pu-dian-hua-1')"
        ></Field>
      </div>

      <div class="form-item mt-10">
        <div class="label">{{ $t('dian-pu-jie-shao') }}</div>
        <Field
          v-model="form.Intro"
          type="textarea"
          :placeholder="$t('qing-shu-ru-dian-pu-jie-shao-2')"
          label=""
        ></Field>
      </div>

      <div class="form-item mt-10">
        <Field
          v-model="form.Welcome"
          :label="$t('jin-dian-huan-ying-yu')"
          :placeholder="$t('qing-shu-ru-jin-dian-huan-ying-yu-1')"
        ></Field>
      </div>

      <div class="form-btn">
        <Button @click="submit">{{ $t('ti-jiao') }}</Button>
      </div>
    </div>
  </div>
</template>
<script>
import { shopSetting, shopInfo } from '@/api/shop'
import headerBar from '@/components/header'
import { Field, Button } from 'vant'
import { getToken } from '@/utils/auth'
import { getBaseUrl } from '@/utils/common'
export default {
  components: {
    headerBar,
    Field,
    Button
  },
  data() {
    return {
      form: {
        Name: '',
        Contact: '',
        Phone: '',
        Logo: '',
        Intro: '',
        Welcome: ''
      },
      headers: {},
      shopInfo: {}
    }
  },
  computed: {
    imgType() {
      return this.$store.state.imgType
    },
    uploadUrl() {
      return this.$store.state.uploadUrl
    }
  },
  mounted() {
    this.init()
    this.headers = {
      Token: getToken()
    }
  },
  methods: {
    getBaseUrl,
    init() {
      shopInfo().then((res) => {
        let data = res.data
        this.shopInfo = res.data
        this.form = {
          Name: data.Name,
          Logo: data.Logo,
          Phone: data.Phone,
          Intro: data.Intro,
          Welcome: data.Welcome,
          Contact: data.Contact
        }
      })
    },
    back() {
      this.$router.go(-1)
    },
    uploadSuccess(res) {
      this.form.Logo = res.data.FileName
    },
    beforeUpload(file) {
      let name = file.name
      let type = name.substring(name.lastIndexOf('.') + 1).trim().toLowerCase();
      if (this.imgType.indexOf(type) === -1) {
        this.$message.error(this.$t('zhi-neng-shang-chuan-tu-pian'));
        return false;
      }
      return true
    },
    submit() {
      shopSetting({
        ...this.shopInfo,
        Name: this.form.Name,
        Logo: this.form.Logo,
        Contact: this.form.Contact,
        Contact: this.form.Contact,
        Intro: this.form.Intro,
        Welcome: this.form.Welcome,
        Phone: this.form.Phone
      }).then((res) => {
        if (res.code == 0) {
          this.$toast(this.$t('xiu-gai-cheng-gong'))
          setTimeout(() => {
            this.back()
          }, 1500)
        } else {
          this.$toast(res.msg)
        }
      })
    }
  }
}
</script>